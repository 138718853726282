import React, { useCallback, useEffect, useState } from 'react';

import {
  Container,
  BannerMain,
  Enjoy,
  Choose,
  Master,
  Skills,
  Stories,
  Stacking,
  Learn,
  Global,
  Modal,
} from './styles';
import bannerMain from '~/assets/banners/banner-home.png';
import bannerMainMob from '~/assets/banners/banner-home-mob-new.png';
import bannerEnjoy from '~/assets/banners/banner-enjoy-new.png';
import bannerEnjoyMob from '~/assets/banners/banner-enjoy-mob-new.png';
import bannerStacking from '~/assets/banners/banner-stacking.png';
import bannerStackingMob from '~/assets/banners/banner-stacking-mob.png';
import bannerMaster from '~/assets/banners/banner-master-new.png';
import cubeLearn from '~/assets/defaults/cube-learn.png';
import bgYoutube from '~/assets/defaults/banner-youtube.png';
import bgFacebook from '~/assets/defaults/banner-facebook.png';
import bgEcommmerce from '~/assets/defaults/banner-ecommerce.png';

import bgYouAds from '~/assets/defaults/banner-ads.png';
import avatar1 from '~/assets/defaults/avatar1.png';
import avatar2 from '~/assets/defaults/avatar2.png';
import avatar3 from '~/assets/defaults/avatar3.png';
import avatar4 from '~/assets/defaults/avatar4.png';

import avatar5 from '~/assets/defaults/avatar5.png';
import avatar6 from '~/assets/defaults/avatar6.png';
import avatar7 from '~/assets/defaults/avatar7.png';
import avatar8 from '~/assets/defaults/avatar8.png';

import avatar9 from '~/assets/defaults/avatar9.png';
import avatar10 from '~/assets/defaults/avatar10.png';
import avatar11 from '~/assets/defaults/avatar11.png';
import avatar12 from '~/assets/defaults/avatar12.png';

import avatar13 from '~/assets/defaults/avatar13.png';
import avatar14 from '~/assets/defaults/avatar14.png';
import avatar15 from '~/assets/defaults/avatar15.png';
import avatar16 from '~/assets/defaults/avatar16.png';

import star from '~/assets/icons/star.svg';
import stars from '~/assets/icons/stars.svg';
import discoverMob1 from '~/assets/defaults/discover1-mob-new.png';
import discoverMob2 from '~/assets/defaults/discover2-mob-new.png';
import discoverMob3 from '~/assets/defaults/discover3-mob-new.png';
import discoverMob4 from '~/assets/defaults/discover4-mob-new.png';
import discover1 from '~/assets/defaults/discover1-new.png';
import discover2 from '~/assets/defaults/discover2.png';
import discover3 from '~/assets/defaults/discover3-new.png';
import discover4 from '~/assets/defaults/discover4.png';
import discover5 from '~/assets/defaults/discover5-new.png';
import discover6 from '~/assets/defaults/discover6.png';
import line from '~/assets/defaults/line-home.png';
import check from '~/assets/defaults/check-home.svg';
import checkWhite from '~/assets/defaults/check-home-white.svg';
import saveDesktop from '~/assets/defaults/save-desk.png';
import saveMobile from '~/assets/defaults/save-mob.png';
import save from '~/assets/defaults/save.png';
import ModalBuyPlan from '~/components/ModalBuyPlan';
import MemberStories from '~/components/MemberStories/indes';
import Newsletter from '~/components/Newsletter';
import { useResize } from '~/hooks/Resize';

const Home: React.FC = () => {
  const { width } = useResize();
  const [showBuyPlan, setShowBuyPlan] = useState(false);
  const [planSelected, setPlanSelected] = useState('launchpad');
  const [showNewsletter, setShowNewsletter] = useState(false);

  const handleBuyPlan = useCallback((planSel) => {
    setShowBuyPlan(true);
    setPlanSelected(planSel);
  }, []);

  useEffect(() => {
    let timer: number;
    if (!showBuyPlan) {
      timer = setTimeout(() => {
        setShowNewsletter(true);
      }, 20000);
    }
    return () => clearTimeout(timer);
  }, [showBuyPlan]);

  return (
    <Container id="home">
      <BannerMain className="container-fluid container-xxl pb-5">
        <div className="row justify-content-center">
          <div className="col-12 text-center mt-5 pt-5">
            <h1 className="text-center">
              Learn Smarter. <span>Grow Faster.</span>
            </h1>
            <h3 className="px-sm-3 my-4 my-lg-3">
              Knowledge is everywhere.{' '}
              <b className="fw-semibold">
                Our AI helps you turn it into results that matter.
              </b>
            </h3>
            <h2 className="text-center px-xl-4">
              Access exclusive tools & personalized insights to{' '}
              <span className="font-weight-bold">
                transform learning into action
              </span>
              —Start Today <span className="d-lg-none">For</span>
              <br className="d-md-none" />
              <span className="fs-span position-relative">
                <span className="d-none d-lg-inline-flex">For</span>{' '}
                <img src={line} alt="Line" className="d-lg-none" />
                <span className="font-weight-bold position-relative d-inline">
                  Just&nbsp;$7
                  <img src={line} alt="Line" className="d-none d-lg-block" />
                </span>
              </span>
            </h2>
            <div className="pt-5">
              <button
                onClick={() => handleBuyPlan('launchpad')}
                type="button"
                className="btn-access mb-3 px-5 mt-5"
              >
                <span role="img" aria-label="flowers">
                  🎉
                </span>{' '}
                Get Early Access!
              </button>
              <p className="px-3 px-sm-0 mb-5">
                <span className="font-weight-regular">
                  Limited seats available
                </span>
                —unlock your growth path today!
              </p>
            </div>
          </div>
          <div className="col-12 px-2 px-lg-5 mb-5 mb-lg-0">
            <img
              src={bannerMain}
              alt="Banner"
              className="d-none d-sm-block w-100 "
            />
            <img src={bannerMainMob} alt="Banner" className="w-100 d-sm-none" />
          </div>
          <div className="col-lg-10 px-lg-0 mt-lg-4 pt-lg-5">
            <div className="box overflow-auto">
              <div className="min-width d-flex align-items-center">
                <div className="text-center">
                  <span className="number d-block">152+</span>
                  <span className="d-block">Courses</span>
                </div>
                <div className="hash" />
                <div className="text-center">
                  <span className="number d-block">375+</span>
                  <span className="d-block">Lessons</span>
                </div>
                <div className="hash" />
                <div className="text-center">
                  <span className="number d-block">3,100+</span>
                  <span className="d-block">Hours</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BannerMain>
      <Enjoy>
        <div className="circle" />
        <div className="bg">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11 text-center">
                <h2 className="text-center mb-4 mt-lg-5 pt-lg-3">
                  Flexible Learning Formats to Fuel Your Business Growth
                </h2>
              </div>
              <div className="col-lg-11 text-center">
                <h3 className="text-center mb-5">
                  Access intelligent,{' '}
                  <span className="fw-semibold">AI-guided</span> video, audio,
                  and text lessons at your own&nbsp;pace.
                  <br className="d-lg-none" /> <br className="d-lg-none" />
                  Our <span className="fw-semibold">flexible formats</span>{' '}
                  deliver practical skills tailored to you, from quick insights
                  to deep dives,{' '}
                  <b>
                    helping you learn & apply smarter to achieve results
                    that&nbsp;matters.
                  </b>
                </h3>
              </div>
              <div className="col-12 d-lg-none text-center">
                <button
                  onClick={() => handleBuyPlan('launchpad')}
                  type="button"
                  className="btn-access mb-5 mb-lg-4 w-100 w-lg-auto"
                >
                  <span role="img" aria-label="flowers">
                    🎉
                  </span>{' '}
                  Unlock for Just $7
                </button>
              </div>
              <div className="col-lg-11 px-sm-4 position-relative">
                <img
                  src={bannerEnjoy}
                  alt="Banner Enjoy"
                  className="d-none d-sm-block enjoy-img mt-5 w-100"
                />
                <img
                  src={bannerEnjoyMob}
                  alt="Banner Enjoy"
                  className="d-sm-none enjoy-img mt-5 w-100"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="triangle" />
      </Enjoy>
      <Choose id="why">
        <div className="container-fluid container-xxl pb-4">
          <div className="row justify-content-center">
            <div className="col-12 mt-4 mb-5 mb-lg-4 pt-5">
              <h2 className="position-relative text-center mb-3">
                <span className="d-block">WHY</span>Choose Wiserr
              </h2>
              <p className="pt-3 mb-4 mb-sm-5">
                We bridge the Knowledge-Action Gap, equipping entrepreneurs with{' '}
                <b>skills that drive real results.</b>
              </p>
            </div>
            <div className="col-md-6 col-lg-4 py-3">
              <div className="card-choose h-100 h-lg-auto">
                <div className="number one">1</div>
                <h2 className="mt-3">
                  Learn Anywhere, <br />
                  Anytime
                </h2>
                <h3>Access courses on any device, wherever you are.</h3>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 py-3">
              <div className="card-choose h-100 h-lg-auto mt-lg-5">
                <div className="number two">2</div>
                <h2 className="mt-3">
                  Growth-Focused <br />
                  Learning
                </h2>
                <h3>Real-world strategies designed for business impact.</h3>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 py-3">
              <div className="card-choose h-100 h-lg-auto">
                <div className="number three">3</div>
                <h2 className="mt-3">
                  Always-On
                  <br />
                  Support
                </h2>
                <h3>Get help whenever you need it, always available.</h3>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 py-3">
              <div className="card-choose h-100 h-lg-auto mt-lg-n5">
                <div className="number four">4</div>
                <h2 className="mt-3">
                  Diverse Learning
                  <br />
                  Experiences
                </h2>
                <h3>Video, audio, and text formats for all learning styles.</h3>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 py-3">
              <div className="card-choose h-100 h-lg-auto">
                <div className="number five">5</div>
                <h2 className="mt-3">
                  Recognized
                  <br />
                  Achievements
                </h2>
                <h3>Earn exclusive certificates to validate your skills.</h3>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 py-3">
              <div className="card-choose h-100 h-lg-auto mt-lg-n5">
                <div className="number six">6</div>
                <h2 className="mt-3">
                  AI-Powered
                  <br />
                  Insights
                </h2>
                <h3>Tailored tools built for real business challenges.</h3>
              </div>
            </div>
          </div>
        </div>
      </Choose>
      <Master>
        <div className="container-fluid container-xxl">
          <div className="row">
            <div className="col-lg-4 pr-lg-0 text-center text-lg-left bg-polygon-mob">
              <h2 className="my-4 mt-lg-5 mb-4">Turn Knowledge Into Results</h2>
              <h3 className="mb-0">
                Close the Knowledge-Action Gap with Wiserr. Our expert-driven
                courses equip you with <b>actionable strategies</b> specifically
                designed for entrepreneurs, empowering you to turn knowledge
                into{' '}
                <span className="font-weight-bold">measurable success.</span>
              </h3>
              <button
                onClick={() => handleBuyPlan('launchpad')}
                type="button"
                className="btn-access mb-5 mb-lg-4 mt-5 w-100 w-sm-auto"
              >
                <span role="img" aria-label="flowers">
                  🎉
                </span>{' '}
                Unlock for Just $7
              </button>
            </div>
            <div className="col-lg-8 pl-lg-5">
              <img src={bannerMaster} alt="Master Banner" />
            </div>
          </div>
        </div>
      </Master>
      <Skills>
        <div className="invert-circle" />
        <div className="container-fluid container-xxl">
          <div className="row justify-content-center">
            <div className="col-12 position-relative" id="courses">
              <img src={stars} alt="Stars" className="stars1" />
              <img src={star} alt="Star" className="star1" />
              <h2 className="my-5">
                Drive Your Business <br className="d-sm-none" />
                Forward with Essential Skills
                <br />
                <span>Anytime, anywhere.</span>
              </h2>
              <div className="col-12 px-lg-5 d-lg-none mb-5">
                <h3 className="mt-5 pt-3">
                  Explore practical courses tailored to every entrepreneurial
                  stage, from foundational skills to advanced strategies,{' '}
                  <b>turning knowledge into measurable growth.</b>
                </h3>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-7 d-flex flex-wrap justify-content-center justify-content-lg-around">
                  <button type="button" className="skills">
                    Online Marketing
                  </button>
                  <button type="button" className="skills">
                    Facebook Ads
                  </button>
                  <button type="button" className="skills">
                    Youtube Ads
                  </button>
                  <button type="button" className="skills">
                    eCommerce
                  </button>
                  <button type="button" className="skills">
                    Marketing
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 px-lg-5 d-none d-lg-block">
              <h3 className="mt-4 pt-3 mb-4">
                Explore practical courses tailored to every entrepreneurial
                stage, from foundational skills to advanced strategies,{' '}
                <b>turning knowledge into measurable growth.</b>
              </h3>
            </div>
            <div className="w-100 overflow-auto bar-scroll">
              <div className="max-w-skills d-flex mt-4 pt-2">
                <div className="px-3 w-25 card-skills mb-3 mb-lg-0">
                  <div className="position-relative">
                    <img
                      src={bgYoutube}
                      alt="Banner youtube"
                      className="w-100 bg-card"
                    />
                    <div className="title">YouTube Workshop</div>
                  </div>
                  <h4 className="mt-4 pt-3">
                    Master the steps to create a standout YouTube channel.
                  </h4>
                  <div className="members-row pt-1">
                    <img src={avatar1} alt="avatar" />
                    <img src={avatar2} alt="avatar" />
                    <img src={avatar3} alt="avatar" />
                    <img src={avatar4} alt="avatar" className="mr-3" />
                    <span className="number-members mr-4">+156 members</span>
                    <a href="/" className="join-members">
                      Join Class NOW
                    </a>
                  </div>
                </div>
                <div className="px-3 w-25 card-skills mb-3 mb-lg-0">
                  <div className="position-relative">
                    <img
                      src={bgFacebook}
                      alt="Banner facebook"
                      className="w-100 bg-card"
                    />
                    <div className="title">Facebook Ads</div>
                  </div>
                  <h4 className="mt-4 pt-3">
                    Gain insights to run profitable Facebook ad campaigns
                  </h4>
                  <div className="members-row pt-1">
                    <img src={avatar5} alt="avatar" />
                    <img src={avatar6} alt="avatar" />
                    <img src={avatar7} alt="avatar" />
                    <img src={avatar8} alt="avatar" className="mr-3" />
                    <span className="number-members mr-4">+228 members</span>
                    <a href="/" className="join-members">
                      Join Class NOW
                    </a>
                  </div>
                </div>

                <div className="px-3 w-25 card-skills mb-3 mb-lg-0">
                  <div className="position-relative">
                    <img
                      src={bgEcommmerce}
                      alt="Banner Ecommerce"
                      className="w-100 bg-card"
                    />
                    <div className="title">Ecom Mastery</div>
                  </div>
                  <h4 className="mt-4 pt-3">
                    Learn how to launch and scale your own e-commerce store
                  </h4>
                  <div className="members-row pt-1">
                    <img src={avatar9} alt="avatar" />
                    <img src={avatar10} alt="avatar" />
                    <img src={avatar11} alt="avatar" />
                    <img src={avatar12} alt="avatar" className="mr-3" />
                    <span className="number-members mr-4">+97 members</span>
                    <a href="/" className="join-members">
                      Join Class NOW
                    </a>
                  </div>
                </div>

                <div className="px-3 w-25 card-skills mb-3 mb-lg-0">
                  <div className="position-relative">
                    <img
                      src={bgYouAds}
                      alt="Banner youtube Ads"
                      className="w-100 bg-card"
                    />
                    <div className="title">YouTube Ads</div>
                  </div>
                  <h4 className="mt-4 pt-3">
                    Discover how you can launch successful youtube ads campaigns
                  </h4>
                  <div className="members-row pt-1">
                    <img src={avatar13} alt="avatar" />
                    <img src={avatar14} alt="avatar" />
                    <img src={avatar15} alt="avatar" />
                    <img src={avatar16} alt="avatar" className="mr-3" />
                    <span className="number-members mr-4">+277 members</span>
                    <a href="/" className="join-members">
                      Join Class NOW
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 text-center mt-lg-5 pb-5 pt-4">
              <div className="d-inline-block position-relative w-100">
                <button
                  onClick={() => handleBuyPlan('launchpad')}
                  type="button"
                  className="btn-access my-4 w-100 w-sm-auto"
                >
                  <span role="img" aria-label="flowers">
                    🎉
                  </span>{' '}
                  Start Growing for Only $7
                </button>

                <img src={stars} alt="Stars" className="stars2" />
                <img src={star} alt="Star" className="star2" />
                <img src={star} alt="Star" className="star3" />
              </div>
            </div>
          </div>
        </div>
      </Skills>
      <Stories id="stories">
        <MemberStories
          textOrange="MEMBER STORIES"
          title="Why Our Members Love Wiserr"
          subTitle="Hear from Entrepreneurs Like You"
          className="margin"
        />
        <div className="circle" />
      </Stories>
      <Stacking>
        <div className="container-fluid container-xxl">
          <div className="row">
            <div className="col-12 mt-lg-5 mb-5">
              <h2 className="mb-lg-5 mb-lg-4">
                Gain Skills That
                <br />
                <span>Future-Proof Your Success</span>
              </h2>
            </div>
            <div className="col-lg-8 px-4 position-relative">
              <img
                src={bannerStacking}
                alt="Banner Stacking"
                className="d-none d-sm-block stacking-img w-100"
              />
              <img
                src={bannerStackingMob}
                alt="Banner Stacking"
                className="d-sm-none stacking-img w-100"
              />
            </div>
            <div className="col-lg-4 pl-lg-4 d-flex flex-column justify-content-center">
              <h3 className="mt-5 mt-lg-0 mb-5 text-center text-lg-right pl-lg-4">
                Give yourself an{' '}
                <span>
                  unfair <b>advantage</b>
                </span>
              </h3>
              <h4 className="mb-5 text-center text-lg-right">
                Skills are the only assets that{' '}
                <span>always grow in value.</span> Stack complementary skills
                with Wiserr to build a resilient foundation,{' '}
                <b className="fw-semibold">
                  ready to thrive in any business climate or career stage.
                </b>
              </h4>
              <div className="d-flex mx-sm-auto mr-lg-0 ml-lg-auto position-relative pb-3 pb-lg-0">
                <button
                  onClick={() => handleBuyPlan('launchpad')}
                  type="button"
                  className="ml-lg-auto btn-access my-4 w-100 w-sm-auto"
                >
                  <span role="img" aria-label="flowers">
                    🎉
                  </span>{' '}
                  Unlock for Just $7
                </button>
                <img
                  src={stars}
                  alt="stars"
                  className="d-lg-none stars-large"
                />
              </div>
            </div>
          </div>
        </div>
      </Stacking>
      <Learn>
        <div className="container-fluid container-xxl">
          <div className="row justify-content-center pt-5">
            <div className="col-lg-11 mt-lg-5 pt-lg-5 position-relative">
              <h2>
                Learn Smarter, Grown Faster
                <br />
                <span>Achieve More!</span>
              </h2>
            </div>
            <div className="col-lg-6">
              <h3>
                Simple Pricing, <b className="font-weight-bold">High Returns</b>
              </h3>
            </div>
            <div className="col-lg-9 mt-3" id="pricing">
              <p className="mg-bottom">
                Information is everywhere—Wiserr{' '}
                <b className="fw-semibold">
                  helps you transform it into success.
                </b>{' '}
                <span className="font-weight-bold">
                  Affordable, easy to access
                </span>
                , and designed to deliver measurable results that{' '}
                <b className="fw-semibold">grow your bottom line.</b>
              </p>
            </div>
          </div>
          <div className="row d-sm-none">
            <div className="col-12">
              <div className="px-5 pt-5 bg-white prices-mob">
                <div>
                  <img src={saveMobile} alt="Save" className="save" />
                </div>
                <div className="text-center mt-5">
                  <h2 className="mb-4">Launchpad</h2>
                  <h3>$7</h3>
                  <p className="mb-5">One-time</p>
                  <button
                    onClick={() => handleBuyPlan('launchpad')}
                    type="button"
                    className="w-100 btn-get-mobile"
                  >
                    Start Learning
                  </button>
                  <span className="d-block text-center under-btn">
                    TEST DRIVE
                  </span>
                </div>
                <div className="text-center mt-5">
                  <h2 className="mb-4">Growth</h2>
                  <h3>$97</h3>
                  <p className="mb-5">Monthly Subscription</p>
                  <button
                    onClick={() => handleBuyPlan('growth')}
                    type="button"
                    className="w-100 btn-get-mobile"
                  >
                    Unlock Access
                  </button>
                  <span className="d-block text-center under-btn">
                    MOST POPULAR{' '}
                    <span role="img" aria-label="star">
                      🌟
                    </span>
                  </span>
                </div>
                <div className="text-center mt-5">
                  <h2 className="mb-4">Accelerator</h2>
                  <h3>$897</h3>
                  <p className="mb-5">Per year</p>
                  <button
                    onClick={() => handleBuyPlan('accelerator')}
                    type="button"
                    className="w-100 btn-get-mobile"
                  >
                    Boost Growth
                  </button>
                  <span className="d-block text-center under-btn">
                    SAVE & GROW
                  </span>
                </div>
                <div className="text-center bg-lifetime py-5 px-4 mt-5 mg-negative-card-mob">
                  <h2 className="text-white mb-0">Freedom</h2>
                  <p className="text-white">Lifetime Access</p>
                  <h3 className="text-white">$1,997</h3>
                  <p className="text-white mb-lg-5">
                    One-time | <b>Best Value</b>
                    <span role="img" aria-label="Comet">
                      💫
                    </span>
                  </p>
                  <button
                    onClick={() => handleBuyPlan('freedom')}
                    type="button"
                    className="w-100 btn-get mt-4"
                  >
                    Lifetime Access
                  </button>
                  <span className="d-block text-center under-btn">
                    INVEST IN YOUR FUTURE
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 position-relative">
              <div className="w-100 you-get d-lg-none">
                <h3>What you get</h3>
                <p>With each plan</p>
              </div>
            </div>
            <div className="col-12 overflow">
              <div className="min-w-table">
                <div className="bg-white d-flex justify-content-center prices">
                  <div className="d-flex w-100 w-lg-80 bg-title px-3 px-lg-0">
                    <div className="d-none d-lg-block w-19 position-relative">
                      <img src={saveDesktop} alt="Save" className="save" />
                    </div>
                    <div className="w-100vw w-sm-25 w-lg-19 text-center">
                      <h2 className="mb-4">Launchpad</h2>
                      <h3>$7</h3>
                      <p className="mb-lg-5">One-time</p>
                      <button
                        onClick={() => handleBuyPlan('launchpad')}
                        type="button"
                        className="d-none d-lg-block mx-auto btn-get"
                      >
                        Start Learning
                      </button>
                      <span className="d-none d-sm-block text-center under-btn">
                        TEST DRIVE
                      </span>
                    </div>
                    <div className="w-100vw w-sm-25 w-lg-19 text-center">
                      <h2 className="mb-4">Growth</h2>
                      <h3>$97</h3>
                      <p className="mb-lg-5">Monthly Subscription</p>
                      <button
                        onClick={() => handleBuyPlan('growth')}
                        type="button"
                        className="d-none d-lg-block mx-auto btn-get"
                      >
                        Unlock Access
                      </button>
                      <span className="d-none d-sm-block text-center under-btn">
                        MOST POPULAR{' '}
                        <span role="img" aria-label="star">
                          🌟
                        </span>
                      </span>
                    </div>
                    <div className="w-100vw w-sm-25 w-lg-24 text-center bg-lifetime px-2 px-lg-3 pt-4 py-sm-4 py-lg-5">
                      <h2 className="mt-3 mt-sm-0 mb-0">Freedom</h2>
                      <p className="">Lifetime Access</p>
                      <h3>$1,997</h3>
                      <p className="mb-lg-5">
                        One-time | <b>Best Value</b>
                        <span role="img" aria-label="Comet">
                          💫
                        </span>
                      </p>
                      <button
                        onClick={() => handleBuyPlan('freedom')}
                        type="button"
                        className="d-none d-lg-block mx-auto btn-get"
                      >
                        Lifetime Access
                      </button>
                      <span className="d-none d-sm-block text-center under-btn">
                        INVEST IN YOUR FUTURE
                      </span>
                    </div>
                    <div className="w-100vw w-sm-25 w-lg-19 text-center">
                      <h2 className="mb-4">Accelerator</h2>
                      <h3 className="position-relative">
                        $897{' '}
                        <img
                          src={save}
                          width={108}
                          height={88}
                          alt="Save"
                          className="save-acce d-none d-lg-block"
                        />
                      </h3>
                      <p className="mb-lg-5">Per year</p>
                      <button
                        onClick={() => handleBuyPlan('accelerator')}
                        type="button"
                        className="d-none d-lg-block mx-auto btn-get"
                      >
                        Boost Growth
                      </button>
                      <span className="d-none d-sm-block text-center under-btn">
                        SAVE & GROW
                      </span>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-negative">
                  <div className="w-100 w-lg-80 px-3 px-lg-0">
                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        AI-Guided Courses
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table-white mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        Tools & Resources
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        Audio Courses
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table-white mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        Text Courses
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>

                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        Automation Tools*
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table-white mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        Full Content Library
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={checkWhite} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        Digital Marketing
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={checkWhite} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table-white mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        Copywriting
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={checkWhite} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>

                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        Sales Funnels
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={checkWhite} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table-white mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        Email Marketing
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={checkWhite} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        eCommerce
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={checkWhite} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-2 w-100 text-center table bg-table-white mb-2">
                      <div className="w-100vw w-sm-25 w-lg-19 d-flex align-items-center text-left pl-4">
                        And Much More...
                        <img
                          src={check}
                          alt="check"
                          className="ml-auto d-lg-none"
                        />
                      </div>
                      <div className="d-none d-lg-block w-lg-19">
                        <img src={checkWhite} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <img src={check} alt="check" />
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <img src={check} alt="check" />
                      </div>
                    </div>
                    <div className="d-flex align-items-center py-2 w-100 text-center mt-4">
                      <div className="w-lg-19 d-none d-lg-flex align-items-center text-left pl-4 " />
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <button
                          onClick={() => handleBuyPlan('launchpad')}
                          type="button"
                          className=" btn-get  "
                        >
                          Start Learning
                        </button>
                        <span className="d-block text-center under-btn-lg">
                          TEST DRIVE
                        </span>
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <button
                          onClick={() => handleBuyPlan('growth')}
                          type="button"
                          className=" btn-get  "
                        >
                          Unlock Access
                        </button>
                        <span className="d-block text-center under-btn-lg">
                          MOST POPULAR{' '}
                          <span role="img" aria-label="star">
                            🌟
                          </span>
                        </span>
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-24">
                        <button
                          onClick={() => handleBuyPlan('freedom')}
                          type="button"
                          className=" btn-get-gold "
                        >
                          Lifetime Access
                        </button>
                        <span className="d-block text-center under-btn-lg">
                          INVEST IN YOUR FUTURE
                        </span>
                      </div>
                      <div className="w-100vw w-sm-25 w-lg-19">
                        <button
                          onClick={() => handleBuyPlan('accelerator')}
                          type="button"
                          className=" btn-get "
                        >
                          Boost Growth
                        </button>
                        <span className="d-block text-center under-btn-lg">
                          SAVE & GROW.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-lg-5 pt-lg-5 position-relative pad-bottom">
              <img src={stars} alt="stars" className="stars" />
              <img src={star} alt="star" className="star1" />
              <img src={star} alt="star" className="star2" />
              <img src={stars} alt="stars" className=" stars-large" />
              <img src={star} alt="star" className=" star-large" />
              <img
                src={cubeLearn}
                alt="cube"
                className="cube-learn d-none d-lg-block"
              />
              <h4 className="pt-5">
                Helping Entrepreneurs <span>Turn Knowledge into Action</span>{' '}
                Daily.
              </h4>
            </div>
          </div>
        </div>
      </Learn>

      <Global>
        <div className="container-fluid container-xxl">
          <div className="row justify-content-center">
            <div className="col-12 position-relative">
              <div className="bg-discover py-5 position-relative">
                <img
                  src={discover1}
                  alt="Person"
                  className="d-none d-md-block pe-none discover1 position-absolute"
                />
                <img
                  src={discover2}
                  alt="Person"
                  className="d-none d-md-block pe-none discover2 position-absolute"
                />
                <img
                  src={discover3}
                  alt="Person"
                  className="d-none d-md-block pe-none discover3 position-absolute"
                />
                <img
                  src={discover4}
                  alt="Person"
                  className="d-none d-md-block pe-none discover4 position-absolute"
                />
                <img
                  src={discover5}
                  alt="Person"
                  className="d-none d-md-block pe-none discover5 position-absolute"
                />
                <img
                  src={discover6}
                  alt="Person"
                  className="d-none d-md-block pe-none discover6 position-absolute"
                />

                <img
                  src={discoverMob1}
                  alt="Person"
                  className="d-md-none pe-none discover-mob1 position-absolute"
                />

                <img
                  src={discoverMob2}
                  alt="Person"
                  className="d-md-none pe-none discover-mob2 position-absolute"
                />

                <img
                  src={discoverMob3}
                  alt="Person"
                  className="d-md-none pe-none discover-mob3 position-absolute"
                />

                <img
                  src={discoverMob4}
                  alt="Person"
                  className="d-md-none pe-none discover-mob4 position-absolute"
                />

                <div className="row">
                  <div className="col-10 col-sm-8 col-xl-7 mx-auto text-center px-0 py-3">
                    <h3 className="mb-4 mb-sm-2 pt-4">
                      LET’S THRIVE <br className="d-md-none" />
                      TOGETHER
                    </h3>

                    <h2 className="mb-4 mb-sm-3">
                      Join a Global Community & Empower Your Journey
                    </h2>

                    <p className="px-4 px-sm-0 mb-5 mb-lg-2 pt-3">
                      Share ideas, gain insights, and accelerate your growth
                      together.
                    </p>

                    <button
                      onClick={() => handleBuyPlan('launchpad')}
                      type="button"
                      className="btn-start my-4 mt-5"
                    >
                      <span role="img" aria-label="flowers">
                        🎉
                      </span>{' '}
                      {`${
                        width > 991
                          ? 'Start Growing for Only $7'
                          : 'Get Early Access'
                      }`}
                    </button>
                  </div>
                </div>
              </div>

              <h4 className="text-center">
                <b className="font-weight-bold">Start growing with Wiserr</b> –
                thousands already have
              </h4>
            </div>
          </div>
        </div>
      </Global>

      <ModalBuyPlan
        show={showBuyPlan}
        planSelected={planSelected}
        onClose={() => setShowBuyPlan(false)}
      />

      <Modal
        className="modal-sales-funnel px-0"
        centered
        size="xl"
        show={showNewsletter}
        onHide={() => setShowNewsletter(false)}
      >
        <Modal.Header className="d-block border-0 pb-0 px-4 pt-4 pt-lg-5 px-lg-5">
          <div className="w-100 text-right">
            <button
              type="button"
              onClick={() => setShowNewsletter(false)}
              className="close-button border-0 bg-transparent"
            >
              Close
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Newsletter />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default Home;
