import React from 'react';

import Slider from 'react-slick';
import person1 from '~/assets/defaults/person-1-stories-new.png';
import person2 from '~/assets/defaults/person-2-stories-new.png';
import person3 from '~/assets/defaults/person-3-stories-new.png';
import person4 from '~/assets/defaults/person-4-stories.png';
import person5 from '~/assets/defaults/person-5-stories-new.png';
import person6 from '~/assets/defaults/person-6-stories-new.png';
import quoteStories from '~/assets/icons/quote-stories.png';

import { Container } from './styles';

interface IProps {
  textOrange?: string;
  title?: string;
  subTitle?: string;
  className?: string;
}

const MemberStories: React.FC<IProps> = ({
  textOrange,
  title,
  subTitle,
  className,
}) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Container className="container-fluid container-xxl">
      <div className="row justify-content-center">
        <div className={`col-lg-9 my-5 mb-lg-0 pt-5 pb-5 pb-lg-0 ${className}`}>
          <h3 className="mb-3 mt-5 pt-lg-5">{textOrange}</h3>
          <h2>{title}</h2>
          <p>{subTitle}</p>
        </div>
        <div className="col-lg-6 px-0 px-sm-3 my-5 mt-lg-0 py-5 position-relative">
          <img src={person1} alt="Person" className="person1" />
          <img src={person2} alt="Person" className="person2" />
          <img src={person3} alt="Person" className="person3" />
          <img src={person4} alt="Person" className="person4" />
          <img src={person5} alt="Person" className="person5" />
          <img src={person6} alt="Person" className="person6" />
          <Slider {...settings}>
            <div>
              <div className="bg mx-3 mx-sm-4 p-4 py-5 p-sm-5">
                <div className="px-4">
                  <img
                    src={quoteStories}
                    alt="Quote"
                    className="quote-stories"
                  />
                </div>
                <h4 className="my-4 px-4">
                  Thanks to Wiserr, we've revolutionized our digital marketing,
                  converting online browsers into committed customers quickly.
                  It's a game-changer - you can do it too
                </h4>
                <h5 className="mb-0">BRAT PALMER</h5>
                <h6 className="mb-3">Senior Brand Designer</h6>
              </div>
              <div className="bg-transparent-1" />
              <div className="bg-transparent-2" />
            </div>
            <div>
              <div className="bg mx-3 mx-sm-4 p-4 py-5 p-sm-5">
                <div className="px-4">
                  <img
                    src={quoteStories}
                    alt="Quote"
                    className="quote-stories"
                  />
                </div>
                <h4 className="my-4 px-4">
                  Wiserr has transformed my approach to learning. The practical
                  courses have given me skills I apply daily in my work.
                </h4>
                <h5 className="mb-0">JAMES NGUYEN</h5>
                <h6 className="mb-3">San Francisco, USA</h6>
              </div>
              <div className="bg-transparent-1" />
              <div className="bg-transparent-2" />
            </div>
            <div>
              <div className="bg mx-3 mx-sm-4 p-4 py-5 p-sm-5">
                <div className="px-4">
                  <img
                    src={quoteStories}
                    alt="Quote"
                    className="quote-stories"
                  />
                </div>
                <h4 className="my-4 px-4">
                  Wiserr's flexible learning options fits great with my
                  schedule. Their engaging courses, especially the Spanish ones,
                  are invaluable!
                </h4>
                <h5 className="mb-0">LUCIA PEREZ</h5>
                <h6 className="mb-3">Madrid, Spain</h6>
              </div>
              <div className="bg-transparent-1" />
              <div className="bg-transparent-2" />
            </div>
            <div>
              <div className="bg mx-3 mx-sm-4 p-4 py-5 p-sm-5">
                <div className="px-4">
                  <img
                    src={quoteStories}
                    alt="Quote"
                    className="quote-stories"
                  />
                </div>
                <h4 className="my-4 px-4">
                  The supportive community and superior content have fueled my
                  career goals and aspirations. Highly recommend!
                </h4>
                <h5 className="mb-0">ABIGAIL MWANGI</h5>
                <h6 className="mb-3">Nairobi, Kena</h6>
              </div>
              <div className="bg-transparent-1" />
              <div className="bg-transparent-2" />
            </div>
          </Slider>
        </div>
      </div>
    </Container>
  );
};

export default MemberStories;
