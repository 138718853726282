import React from 'react';
import ReactDOM from 'react-dom';
import Clarity from '@microsoft/clarity';

import App from './App';

Clarity.init('q42w7f630k');
Clarity.init('pcwt6qkphg');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
