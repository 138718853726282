import styled from 'styled-components';

import arrowNext from '~/assets/icons/arrow-corousel-stories.svg';
import arrowPrev from '~/assets/icons/arrow-corousel-prev-stories.svg';

export const Container = styled.div`
  h2 {
    color: #15141e;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
  }

  h3 {
    color: #ffa33b;
    text-align: center;
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.9px;
  }

  h4 {
    color: #b1b1b5;
    font-family: 'Quicksand';
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
  }

  h5 {
    color: var(--colors-text, #b1b1b5);
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.9px;
  }

  h6 {
    color: #37ebaa;
    text-align: center;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  p {
    color: #000;
    text-align: center;
    font-family: 'Quicksand';
    font-size: 24px;
    font-weight: 400;
    line-height: 48px;
  }

  .bg {
    border-radius: 40px;
    background: #18191a;
  }

  .quote-stories {
    width: 59px;
    height: 41px;
  }

  .bg-transparent-1 {
    border-radius: 30px;
    background: rgba(24, 25, 26, 0.5);
    width: 82%;
    height: 60px;
    margin: -30px auto 0 auto;
  }

  .bg-transparent-2 {
    border-radius: 30px;
    background: rgba(24, 25, 26, 0.25);
    width: 70%;
    height: 90px;
    margin: -60px auto 0 auto;
  }

  .slick-prev,
  .slick-next {
    top: 115%;
  }

  .slick-prev {
    left: 43%;
  }

  .slick-next {
    right: 43%;
  }

  .slick-next:before {
    content: url(${arrowNext});
  }

  .slick-prev:before {
    content: url(${arrowPrev});
  }

  .person1 {
    position: absolute;
    top: -32%;
    left: -30%;
  }
  .person2 {
    position: absolute;
    right: -23%;
    top: -29%;
  }
  .person3 {
    position: absolute;
    left: -35%;
    top: 27%;
  }
  .person4 {
    position: absolute;
    right: -36%;
    top: 23%;
  }
  .person5 {
    position: absolute;
    left: -20%;
    top: 87%;
  }
  .person6 {
    position: absolute;
    right: -22%;
    top: 83%;
  }

  @media screen and (max-width: 991px) {
    .person1 {
      top: -21%;
      left: 10%;
    }

    .person2 {
      top: 89%;
      left: 73%;
    }

    .person3 {
      top: 103%;
      left: 9%;
    }
    .person4 {
      top: 120%;
      left: 64%;
    }
    .person5 {
      left: 44%;
      top: -24%;
    }
    .person6 {
      right: 11%;
      top: -12%;
    }
  }

  @media screen and (max-width: 575px) {
    h2 {
      letter-spacing: -1.6px;
    }
    .person1 {
      top: -17%;
      left: -1%;
    }

    .person2 {
      right: 1%;
      left: auto;
      top: -10%;
    }

    .person3 {
      top: 118%;
      left: 53%;
      width: 149px;
      height: 149px;
    }
    .person4 {
      top: 92%;
      left: 67%;
      width: 128px;
      height: 128px;
    }
    .person5 {
      left: 35%;
      top: -20%;
    }
    .person6 {
      top: 103%;
      left: 4%;
      right: auto;
    }

    .slick-prev {
      left: 41%;
    }

    .slick-next {
      right: 41%;
    }
  }
`;
